@import 'styles/core/variables.scss';

.about-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-card img {
  max-width: 420px;
  width: 100%;
  border-radius: 100%;
  background-color: #3eb5f0;
  // background-image: linear-gradient(135deg, #ffb22d 0%, rgba(255, 102, 45, 0.8) 100%);
}

.pg-about-card-title {
  color: #38424f;
  font-size: 1.25rem;
  font-weight: 600;
  -webkit-box-align: center;
  align-items: center;
  padding-bottom: 1rem;
}

.pg-about-card-name {
  width: 100%;
  text-align: center;
  color: var(--bs-primary);
  font-weight: 500;
  font-size: 2rem;
  padding-top: 1rem;
}
.pg-about-card-info {
  margin-bottom: 0.75rem;
}
