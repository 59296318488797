@import 'styles/core/variables.scss';

#sciops-banner {
  padding-left: 20px;
  position: absolute;
  z-index: 10;
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  .header-text {
    color: #fff;
    font-size: 3.5rem;
    // font-weight: 700;
    line-height: 1.2;
    font-family: 'Russo One';
  }
  .header-btn {
    min-width: 20vw;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .home-plasma-img img {
    height: 3rem;
    max-width: 100%;
  }

  .power-up-text {
    font-size: 1.35rem;
  }
}
#career-banner {
  position: absolute;
  z-index: 10;
  top: 72%;
  right: 30.6%;
  display: flex;
  justify-content: center;
}
::ng-deep.carousel-item {
  transition: transform 1.5s ease-in-out;
}
